/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
import { useApolloClient } from '@apollo/client';
import { custDataNameCookie, customerTokenKeyClient, features, modules, nameGlobalCookie } from '@config';
import { getCategories, getCustomerLazy, removeToken } from '@core_modules/theme/services/graphql';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import { priceVar } from '@root/core/services/graphql/cache';
import { localCompare, localTotalCart } from '@services/graphql/schema/local';
import Content from '@core_modules/theme/components/header/components';
import firebase from 'firebase/app';
import Cookies from 'js-cookie';
import Router from 'next/router';

const CoreTopNavigation = (props) => {
    const {
        dataMenu: propsMenu,
        storeConfig,
        t,
        isLogin,
        showGlobalPromo,
        enablePopupInstallation,
        appName,
        installMessage,
        isHomepage,
        deviceType,
        ...other
    } = props;
    let data = propsMenu;
    let loading = !propsMenu;
    const [deviceWidth, setDeviceWidth] = React.useState(0);
    const [customerData, setCustomerData] = React.useState({});
    const [getCustomerInfoLazy] = getCustomerLazy();
    if (!data && storeConfig && storeConfig.pwa) {
        const { data: dataMenu, loading: loadingMenu } = getCategories();
        data = dataMenu;
        loading = loadingMenu;
    }
    const [value, setValue] = React.useState('');
    const [deleteTokenGql] = removeToken();
    const client = useApolloClient();

    React.useEffect(() => {
        // get customer info data
        const getCustomerInfo = async () => {
            if (isLogin) {
                const res = await getCustomerInfoLazy();
                const resData = res?.data;
                if (resData) {
                    setCustomerData(resData);
                }
            }
        };

        if (typeof window !== 'undefined') {
            getCustomerInfo();
            setDeviceWidth(window.innerWidth);
        }
    }, []);

    const handleLogout = async () => {
        window.backdropLoader(true);
        if (features.firebase.config.apiKey && features.firebase.config.apiKey !== '') {
            if (typeof firebase?.auth === 'function') {
                firebase?.auth()?.signOut();
            }
        }
        await deleteTokenGql()
            .then(() => {
                Cookies.remove(custDataNameCookie);
                Cookies.remove(nameGlobalCookie);
                Cookies.remove(customerTokenKeyClient);
                Cookies.remove('checkout_token');
                Cookies.remove('admin_id');
                Cookies.remove('login_with_phone');
                Cookies.remove('uid_product_compare');
                removeIsLoginFlagging();
                removeCartId();
                priceVar({});
                client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
                client.writeQuery({ query: localCompare, data: { item_count: 0 } });
                window.backdropLoader(false);
                Router.push('/customer/account/login');
            })
            .catch(() => {
                window.backdropLoader(false);
                Router.push('/customer/account/login');
            });
    };

    const handleSearch = (ev) => {
        if (ev.key === 'Enter' && ev.target.value !== '') {
            Router.push(`/catalogsearch/result?q=${encodeURIComponent(ev.target.value)}`);
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            Router.push(`/catalogsearch/result?q=${encodeURIComponent(value)}`);
        }
    };
    return (
        <Content
            t={t}
            isLogin={isLogin}
            data={data}
            loading={loading}
            storeConfig={storeConfig}
            handleSearch={handleSearch}
            searchByClick={searchByClick}
            setValue={setValue}
            customer={customerData}
            handleLogout={handleLogout}
            value={value}
            showGlobalPromo={showGlobalPromo}
            modules={modules}
            enablePopupInstallation={enablePopupInstallation}
            appName={appName}
            installMessage={installMessage}
            isHomepage={isHomepage}
            deviceType={deviceType}
            deviceWidth={deviceWidth}
            {...other}
        />
    );
};

export default CoreTopNavigation;
